
body {
  background: #242526;
  color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
}

:root {
  --color-1: #242526;
  --color-2: white;
  --color-3: #0084ff;
  --color-4: grey;
  --color-5: #eb4034;
  --color-6: #333;
}

/*Header*/

.custom-head {
  color:aqua;
  text-align: center;
  text-shadow: 2px 2px #00786F;
  font-family: Verdana, sans-serif;
  padding: 70px;
  font-size: 60px;
}

/*Emblems */
.emblem-link {
  border-radius: 50%;
  transition: all 400ms ease-out;
  box-shadow: 0 0 8px 3px #00F4F7;
  border: 2px solid #00F4F7;
}

.emblem-link:hover {
  transition: all 200ms ease-in;
  box-shadow: 0 0 8px 3px #C900FF;
  border: 2px solid #C900FF;
}

/* Footer */
.foot-color {
  color: white;
}

/* Accordion */
.accord-align {
  position: relative;
  align-content: center;
  padding: 50px;
}

.btn-align {
  align-self: center;
}

.questions {
  font-family: Verdana, sans-serif;
  font-size: large;
}

.accord-answers {
  text-align: center;
}

/* Calendar */
.box-content {
  margin: 5% 0%;
  padding-left: 10%;
  padding-right: 10%;
}
.modal-color {
  color: black;
}

/*Nav*/

.topnav-right {
  float: right;
}

/* Register */
section .form {
  margin-top: 30px;
  padding: 0px 20px;
}
.input_container {
  max-width: 50%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}
.input_container input {
  width: 100%;
  padding: 10px;
  outline: none;
  margin-top: 10px;
  border: 1px solid var(--color-6);
  border-radius: 5px;
}
.btn_container {
  margin: 10px 0px;
  text-align: center;
}
.btn {
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--color-4);
  background: var(--color-1);
  color: var(--color-2);
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  font-size: 16px;
}
.btn:hover {
  transform: scale(1.05);
}
.error {
  text-align: center;
  color: var(--color-5);
}

/* Home */
.home_title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 60px;
  text-align: center;
  margin-top: 40px;
  color:aqua;
  text-shadow: 4px 3px 1px #00786F;
}

.home_picture {
  width: 600px;
  height: 400px;
}

/*MapBox*/

.map-title {
  color:aqua;
  text-align: center;
  text-shadow: 2px 2px #00786F;
  font-family: Verdana, sans-serif;
  padding: 80px;
}

.resource-info {
  font-weight: bold;
}

.map-pos {
  position: relative;
  padding: 10% 20% 20%;
  
  
}
.map-align {
  border: 2px solid #00F4F7;
  border-radius: 20px;
  box-shadow: 0 0 8px 3px #00F4F7;
}
.office-popup {
  color: black;
}
.office-mrk {
  background: none;
  border: none;
  cursor: pointer;
}

.office-mrk img {
  width: 20px;
  height: 20px;
}

/* Profile */
.profile_container {
  display: flex;
  align-items: center;
}
.img_container {
  position: relative;
  margin-right: 20px;
}
.img_container img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid var(--color-4);
  transition: 0.5s ease-in-out all;
}
.img_container:hover img {
  opacity: 0.4;
}
.img_container:hover .overlay {
  opacity: 1;
}
.overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.text_container {
  flex-grow: 1;
}
.text_container h3 {
  text-align: left;
}

.home_container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  overflow: hidden;
  height: calc(100vh - 70px);
  width: 100vw;
}
.users_container {
  margin-top: 10px;
  border-right: 2px solid var(--color-6);
  overflow-y: auto;
}
.user_wrapper {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}
.user_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_detail {
  display: flex;
  align-items: center;
}
.user_detail h4 {
  margin-left: 10px;
}
.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--color-4);
}
.user_status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.online {
  background: #34eb52;
}
.offline {
  background: var(--color-5);
}
.messages_container {
  position: relative;
  width: 100%;
}
.messages_user {
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid var(--color-6);
}
.no_conv {
  font-size: 20px;
  color: var(--color-4);
  text-align: center;
}
.message_form {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}
.message_form input {
  width: 40vw;
  margin: 0px 10px 10px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
}
.message_form .btn {
  margin-top: -10px;
}
.messages {
  height: calc(100vh - 200px);
  overflow-y: auto;
  border-bottom: 1px solid var(--color-6);
}
.message_wrapper {
  margin-top: 5px;
  padding: 0px 5px;
}
.message_wrapper img {
  width: 100%;
  border-radius: 5px;
}
.message_wrapper p {
  padding: 10px;
  display: inline-block;
  max-width: 50%;
  text-align: left;
  border-radius: 5px;
}
.message_wrapper small {
  display: inline-block;
  margin-top: 15px;
  opacity: 0.8;
}
.message_wrapper.own {
  text-align: right;
}
.me {
  background: var(--color-3);
  color: white;
}
.friend {
  background: var(--color-6);
}
.selected_user {
  background: var(--color-6);
}
.truncate {
  font-size: 14px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate strong {
  margin-right: 10px;
}
.unread {
  margin-left: 10px;
  background: var(--color-3);
  color: white;
  padding: 2px 4px;
  border-radius: 10px;
}
.sm_screen {
  display: none;
}
@media screen and (max-width: 767px) {
  .home_container {
    grid-template-columns: 2fr 3fr;
  }
  .message_form {
    left: 3%;
    right: 0;
    bottom: 5px;
  }
  .message_wrapper p {
    max-width: 75%;
  }
}

@media screen and (max-width: 576px) {
  .home_container {
    grid-template-columns: 1fr 5fr;
  }
  .user_wrapper {
    display: none;
  }
  .sm_container {
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
  }
  .sm_screen {
    display: inline-block;
  }
  .message_form input {
    width: 50vw;
    margin: 0px 10px;
  }
  .message_form .btn {
    margin: 0px;
  }

  .message_wrapper p {
    max-width: 100%;
  }

}
